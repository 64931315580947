.gallery-name {
    font-weight: 400;
    font-size: 120%;
    margin-top: 5em;
}

.gallery-image {
    width: 100%;
    height: auto;
    padding: 2em 0em;
}