#white-screen {
    height: 100%;
    transform: translateY(100%);
    background-color: rgb(255, 255, 255);
    transition: all 1.7s cubic-bezier(.82,-0.03,.35,.75);
    position: relative;
    z-index: 100;

    &.transition-in {
        transform: translateY(0%);
    }
}

nav {
    margin-top: 2.5em;
    margin-bottom: 0.5em;
    transition: all 0.6s cubic-bezier(.64,.18,.34,.95) 0.3s;

    &.transition-out {
        opacity: 0 !important;
        transform: translateY(-150%);
    }
}

.brand {
    font-size: 105%;
    margin-top: -5px;
}
.brand__sub {
    font-size: 75%;
}

.n-item {
    cursor: pointer;
    text-decoration: none;
    color: var(--grey-500);
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: var(--teal-400);
        transform: scale(1.05);
    }
    &:active {
        transform: scale(0.95);
    }
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    height: 0;
    overflow: hidden;
    justify-content: start;
}

.menu-icon {
    display: none;
    width: 1.5em;
    padding: 0;
}

li {
    height: 3em;

    .nav-link {
        color: var(--black);
        display: none;
        align-items: center;
        text-decoration: none;
        transition: all 0.3s ease;
    }
}

.fa-bars, .fa-xmark {
    color: var(--black);
}

@media screen and (max-width: 640px) {
    #home, #about {
        display: none !important;
    }
    #brand {
        margin: auto;
        padding-right: 2em;
    }
    nav {
        margin-bottom: 1.5em;
        transition: none;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 82vh;
        position: absolute;
        top: 5em;
        overflow: visible !important;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        width: 96%;
        height: 40%;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        left: 0;
        background-image: linear-gradient(to bottom, var(--grey-100), var(--grey-100), rgba(255, 255, 255, 0));
        transition: all 0.3s ease;
        z-index: 1;
        opacity: 1;

        li .nav-link {
            text-align: center;
            width: 100%;
            height: 100%;
            padding-top: 0.6em;
            display: table;
            border-radius: 5px;
        }
        li .nav-link:hover {
            background-color: var(--grey-200);
            color: var(--black);
            transform: scale(1.05);
        }
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 2em;
        right: 0.5em;
        transform: translateX(-80%);
        cursor: pointer;
    }
}
