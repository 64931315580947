.overview-container {
    padding: 7em 6em 3em 6em;
}

.overview-type {
    font-weight: 300;
    font-size: 220%;
    width: 70%;
    text-transform: uppercase;
}

.overview-date {
    padding: 1em 0;
    font-size: 135%;
    color: var(--grey-400);
    z-index: 5;
}

.overview-desc {
    position: relative;
    width: 70%;
    line-height: 1.7;
    z-index: 5;
}

.overview-tags {
    width: 14em;
    position: relative;
    margin: -11.1em -1em auto auto;
    color: var(--blue-500);
    z-index: 5;
    white-space: nowrap;
    
    ul { 
        list-style-type: none; 
        li { height: 1.7em; }
    }
}

.overview-box {
    width: 210px;
    height: 270px;
    position: absolute;
    top: 14em;
    margin: auto auto auto 760px;
    background-color: var(--blue-200);
    z-index: 1;
}

.overview-line {
    width: 5px;
    height: 170px;
    position: absolute;
    top: 16em;
    background-color: var(--blue-300);
    margin: auto auto auto -2em;
}

.dots-1, .dots-2 {
    width: 115px;
    height: auto;
    margin: 5em auto auto auto;
    z-index: 0;
}
.dots-2 {
    width: 105px;
    margin: -45em auto auto 93%;
}


@media screen and (min-width: 1403px) {
    .overview-tags {
        margin-top: -11.2em;
    }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
    .overview-tags {
        margin-top: -14.4em;
        margin-right: -3em;
    }
    .dots-1 {
        margin-top: 8em;
    }
    .dots-2 {
        margin-top: -50em;
    }
}

@media screen and (min-width: 766px) and (max-width: 991px) {
    .overview-type {
        width: 100%;
    }
    .overview-tags {
        margin-top: -21.1em;
        margin-right: -7em;
    }
    .overview-container {
        padding-bottom: 5em;
    }
    .overview-box {
        margin-top: 3em;
    }
    .overview-line {
        top: 18.5em;
    }
    .dots-2 {
        margin: -43em auto auto 105%;
    }
}

@media screen and (max-width: 766px) {
    .overview-container {
        padding: 7em 4em 0em 4em;
    }
    .overview-type {
        font-size: 220%;
        width: 100%;
    }
    .overview-date {
        font-size: 130%;
    }
    .overview-desc {
        width: 100%;
        font-size: 100%;
        line-height: 2;
    }
    .overview-tags {
        font-size: 100%;
        margin-top: 2em;
        margin-right: 3em;
        padding-bottom: 7em;
        line-height: 2;
    }
    .overview-box {
        margin-top: 20em;
        width: 220px;
        height: 200px;
    }
    .overview-line {
        top: 18.5em;
    }
    .dots-1 {
        width: 90px;
        margin: -25em auto -15em auto;
    }
    .dots-2 {
        width: 85px;
        margin: -95em auto auto 78%;
    }
}


@media screen and (max-width: 490px) {
    .overview-box {
        margin-top: 22em;
    }
    .dots-2 {
        margin: -100em auto auto 85%;
    }
}
@media screen and (max-width: 460px) {
    .overview-box {
        margin-top: 25em;
    }
    .dots-2 {
        display: none;
    }

}
@media screen and (max-width: 435px) {
    .overview-box {
        margin-top: 28em;
    }
    .dots-2 {
        display: none;
    }
}
@media screen and (max-width: 414px) {
    .overview-box {
        margin-top: 30em;
    }
    .dots-2 {
        display: none;
    }
}
@media screen and (max-width: 406px) {
    .overview-line {
        top: 21em;
    }
    .overview-box {
        margin-top: 35em;
    }
    .dots-2 {
        display: none;
    }
}
@media screen and (max-width: 376px) {
    .overview-box {
        margin-top: 39em;
        width: 200px;
    }
    .dots-2 {
        display: none;
    }
}
@media screen and (max-width: 360px) {
    .overview-box {
        margin-top: 41em;
    }
    .dots-2 {
        display: none;
    }
}
@media screen and (max-width: 354px) {
    .overview-line {
        top: 23em;
    }
    .overview-box {
        margin-top: 43em;
        width: 180px;
    }
    .overview-container {
        padding: 7em 3.5em 3em 3.5em;
    }
    .dots-2 {
        display: none;
    }
}
@media screen and (max-width: 340px) {
    .overview-box {
        margin-top: 52em;
    }
    .dots-2 {
        display: none;
    }
}

