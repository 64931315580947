.footer-container {
    background-color: var(--black);
    padding: 5em;
}

.footer-button {
    background-color: white;
    padding: 0.7em 2.5em;
    display: block;
    margin: auto;
    font-weight: 300;
    font-size: 110%;
    border: none;
    border-radius: 3px;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(105%);
    }
    &:active {
        transition: all 0.3s ease;
        transform: scale(100%);
    }
    &:focus {
        background-color: var(--grey-200);
    }
}

.footer-text {
    color: var(--grey-400);
    display: block;
    text-align: center;
    margin: 6em auto -3em auto;
}