.project-container {
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 7em;
    left: 0em;
    box-sizing: border-box;
    transform-style: preserve-3d;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: transform 1.3s cubic-bezier(0.73, -0.35, 0.27, 1);
    z-index: 0;
    background-image: url('../../../../public/images/dot-grid.png') ;
    background-image: url('../../../../public/images/dot-grid.webp') !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.projects {
    width: 100%;
    height: 100%;
}

.project {
    position: absolute;
    width: 50%;
    height: 15em;
    top: 0;
    transition: all 0.7s cubic-bezier(.9,.04,.41,.96);
    cursor: pointer !important;

    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: 15 !important;
        object-fit: cover;
        object-position: top center;
        box-shadow: 0 0 7px rgba(139, 145, 151, 0.493);
        background-color: white;
    }

    &.selected {
        transition: transform 1.3s cubic-bezier(0.73, -0.35, 0.27, 1) !important;
        transform: scale(225%) !important;
        z-index: 1000;
    }
}

.project--label, .project--type, .project--date {
    position: relative;
    color: var(--black);
    text-align: center;
    z-index: 20 !important;
}
.project--label {
    width: calc(100% - 0.15em);
    height: 1.25em;
    margin: auto;
    display: block;
    margin-top: 0.43em;
    padding: 6px 40px 0px 40px;
    font-size: 8rem;
    font-stretch: extra-expanded;
    letter-spacing: 0.1em;
    font-weight: 700;
    mix-blend-mode: screen;
    background-color: rgba(255, 255, 255, 0.904);
    line-height: 1.15em;
    cursor: pointer;
    transition: transform 1s ease-out ;
    border-radius: 5px;
}

.transition {
    transition: all 0.5s ease 0.3s !important;
    opacity: 0 !important;
}

#item-1:active ~ #project-1 img,
#item-2:active ~ #project-2 img,
#item-3:active ~ #project-3 img {
  transform: scale(0.9);
  transition: transform 0.6s ease;
}

#item-1:focus ~ div #project--type, #item-2:focus ~ div #project--type, #item-3:focus ~ div #project--type, #item-1:focus ~ div #project--date, #item-2:focus ~ div #project--date, #item-3:focus ~ div #project--date {
    animation: fade-in 2s ease;
    -moz-animation: fade-in 2s ease;
    -webkit-animation: fade-in 2s ease;
    -o-animation: fade-in 2s ease;
    -ms-animation: fade-in 2s ease;
}

#item-1:checked ~ #item-2::after {
    left: var(--x, -200%);
}
#item-3::after {
    left: var(--x, -200%);
}

#item-1 {
    margin-inline-start: -3.5em;
}
#item-2 {
    margin-inline-start: 0em;
}
#item-3 {
    margin-inline-start: 3.5em;
}

#item-1:checked ~ #project-3, #item-2:checked ~ #project-1, #item-3:checked ~ #project-2 {
    transform: translatex(-50%) scale(0.7);
    z-index: 1;
}
#item-1:checked ~ #project-2, #item-2:checked ~ #project-3, #item-3:checked ~ #project-1 {
    transform: translatex(50%) scale(0.7);
    z-index: 1;
}
#item-1:checked ~ #project-1, #item-2:checked ~ #project-2, #item-3:checked ~ #project-3 {
    transform: translatex(0) scale(1);
    z-index: 10;
}

#item-1:checked ~ #project-3 img, #item-2:checked ~ #project-1 img, #item-3:checked ~ #project-2 img {
    transition: all 0.3s ease 0.1s;
    opacity: 0.4;
}
#item-1:checked ~ #project-2 img, #item-2:checked ~ #project-3 img, #item-3:checked ~ #project-1 img {
    transition: all 0.3s ease 0.1s;
    opacity: 0.4;
}
#item-1:checked ~ #project-1 img, #item-2:checked ~ #project-2 img, #item-3:checked ~ #project-3 img{
    transition: all 0.3s ease 0.1s;
    opacity: 1;
    z-index: 10;
}

#project-1, #project-2, #project-3 {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

#project--label {
    transition: all 0.5s ease;
}

#project--type, #project--date {
    text-align: center;
    z-index: 2;
    opacity: 1;
}

#project--type {
    font-size: 130%;
    font-stretch: expanded;
    margin-top: 3.5em;
    transition: all 0.5s ease;
}

#project--date {
    font-size: 80%;
    color: var(--grey-500);
    line-height: 0;
    margin-top: -0.3em;
    transition: all 0.5s ease;
}

input[type=radio] {
    --radio: var(--teal-400);
    --radio-checked: var(--teal-400);
    --radio-size: 20px;
    position: absolute;
    top: 21.5em;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.1em;
    height: 1.1em;
    outline: none;
    cursor: pointer;
    border: 0.1em solid var(--grey-200);
    background: transparent;
    border-radius: 50%;
    display: grid;
    justify-self: end;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s ease;
  
    &.transition {
        opacity: 0 !important;
    }
  }
  
  input[type=radio]:hover, input[type=radio]:checked {
    border: 0.11em solid var(--teal-200);
  }
  
  input[type=radio]::before,
  input[type=radio]::after {
    content: "";
    display: flex;
    justify-self: center;
    border-radius: 50%;
  }
  
  input[type=radio]::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
    opacity: var(--opacity, 1);
  }
  
  input[type=radio]::after {
    position: relative;
    width: 80%;
    height: 80%;
    margin-top: -0.5px;
    background: var(--radio-checked);
    left: var(--x, 200%);
    transition: left 0.5s cubic-bezier(0.5, 1.7, 0.6, 0.7);
  }
  
  input[type=radio]:checked::after {
    --radio: var(--radio-checked);
    --x: 0%;
    animation: stretch-animate 0.3s ease-out 0.17s;
    -moz-animation: stretch-animate 0.3s ease-out 0.17s;
    -webkit-animation: stretch-animate 0.3s ease-out 0.17s;
    -o-animation: stretch-animate 0.3s ease-out 0.17s;
    -ms-animation: stretch-animate 0.3s ease-out 0.17s;
  }
  
  input[type=radio]:checked::before {
    --opacity: 0;
  }
  
  input[type=radio]:not(:checked)::before {
    --opacity: 1;
    transition: opacity 0s linear 0.5s;
  }
  
  .cursor-default {
    cursor: default !important;
  }


@media screen and (max-width: 640px) {
    .project {
        width: 50%;
    }
    .project--label {
        margin-top: 0.6em;
        font-size: 600%;
        padding-left: 0.2em;
        padding-right: 0.2em;
    }
    #project--type{
        margin-top: 4.3em;
        transform: scale(90%);
        transition: none;
    }
}

@keyframes fade-in {
    0% {
        transform: translateY(10%);
        opacity: 0.3;
    }
    30% {
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes fade-in {
    0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
}

@keyframes stretch-animate {
    0% {
      transform: scale(1, 1);
    }
    28% {
      transform: scale(1.15, 0.85);
    }
    50% {
      transform: scale(0.9, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
}
@-moz-keyframes stretch-animate {
    0% {
      transform: scale(1, 1);
    }
    28% {
      transform: scale(1.15, 0.85);
    }
    50% {
      transform: scale(0.9, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
}
@-webkit-keyframes stretch-animate {
    0% {
      transform: scale(1, 1);
    }
    28% {
      transform: scale(1.15, 0.85);
    }
    50% {
      transform: scale(0.9, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
}

@keyframes fade-out-up {
    0% {
        opactity: 1;
        transform: translateY(0%);
    }
    100% {
        opactity: 0;
        transform: translateY(-50%);
    }
}
@-moz-keyframes fade-out-up {
    0% {
        opactity: 1;
        transform: translateY(0%);
    }
    100% {
        opactity: 0;
        transform: translateY(-50%);
    }
}
@-webkit-keyframes fade-out-up {
    0% {
        opactity: 1;
        transform: translateY(0%);
    }
    100% {
        opactity: 0;
        transform: translateY(-50%);
    }
}



@media screen and (max-width: 412px) {
    .project {
        width: 50%;
    }
    .project--label {
        margin-top: 0.6em;
        font-size: 580%;
        padding-left: 0.1em;
        padding-right: 0.1em;
    }
    #project--type{
        margin-top: 4.3em;
        transform: scale(90%);
        transition: none;
    }
}